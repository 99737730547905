'use strict';

var $ = require('../internals/export');
var requireObjectCoercible = require('../internals/require-object-coercible');
var toInteger = require('../internals/to-integer');
var slice = ''.slice;
var max = Math.max;
var min = Math.min;

// `String.prototype.substr` method
// https://tc39.es/ecma262/#sec-string.prototype.substr
$({
  target: 'String',
  proto: true
}, {
  substr: function substr(start, length) {
    var that = String(requireObjectCoercible(this));
    var size = that.length;
    var intStart = toInteger(start);
    var intLength, intEnd;
    if (intStart === Infinity) intStart = 0;
    if (intStart < 0) intStart = max(size + intStart, 0);
    intLength = length === undefined ? size : toInteger(length);
    if (intLength <= 0 || intLength === Infinity) return '';
    intEnd = min(intStart + intLength, size);
    return intStart >= intEnd ? '' : slice.call(that, intStart, intEnd);
  }
});